import {useEffect, useState} from 'react';
import './index.css';
import styles from './AiName.module.css';
import {TextArea} from '../../../../common-lib/src/components/TextArea/TextArea';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import cn from 'classnames';
import {nameDescriptionSuggestions} from './data';

type Props = {
	showError?: boolean;
	onChange: (data: string) => void;
	value: string;
	className?: string;
	placeholder?: string;
};

const getShuffledSuggestions = (except = [''], size = 12) =>
	Object.keys(nameDescriptionSuggestions)
		.sort(() => 0.5 - Math.random())
		.filter((key) => !except.includes(key))
		.slice(0, size);

const getNonShuffledSuggestions = () => Object.keys(nameDescriptionSuggestions);

export const AiName = ({
	showError,
	onChange,
	value,
	className,
	placeholder,
}: Props) => {
	const [description, setDescription] = useState<string>(value);
	const [suggestions, setSuggestions] = useState(getNonShuffledSuggestions());

	const handleDescriptionsChange = (value: string) => {
		let updated = value
			.replaceAll('• ', '')
			.replaceAll('•', '')
			.split('\n')
			.filter((d, i, a) => d.length > 0 || i === a.length - 1);
		if (updated.length > 1) {
			return;
		}
		if (updated.length === 1 && updated[0] === '') {
			handleChangeDescription('');
		} else {
			if (
				description.length === updated.length &&
				updated[updated.length - 1] === '' &&
				description[description.length - 1] === ''
			) {
				updated = updated.filter((d) => !!d);
			}
			handleChangeDescription(updated[0]);
		}
	};

	const handleAddBySuggestion = (key: string) => {
		setSuggestions(getNonShuffledSuggestions());

		//@ts-ignore
		const description = nameDescriptionSuggestions[key];
		handleChangeDescription(description);
	};

	const handleChangeDescription = (text: string) => {
		setDescription(text);
		onChange(text);
	};

	useEffect(() => {
		setDescription(value);
	}, [value]);

	return (
		<div
			className={cn(
				styles.container,
				{
					[styles.container_invalid]: showError && !suggestions.length,
				},
				className
			)}
		>
			<TextArea
				maxLength={140}
				onChange={handleDescriptionsChange}
				className={cn(
					'edit-bot__textarea edit-bot__textarea_description',
					styles.textarea
				)}
				value={description}
				placeholder={
					placeholder || 'Cheerful girl with blue eyes and brown hair'
				}
			/>

			<div
				className={cn(
					'edit-bot__descriptions__suggestions',
					styles.suggestions
				)}
			>
				{suggestions.map((text) => {
					return (
						<InButton
							id={text}
							className={cn('suggestion', {
								[styles.suggestionActive]:
									nameDescriptionSuggestions[
										text as keyof typeof nameDescriptionSuggestions
									] === description,
							})}
							onClick={() => handleAddBySuggestion(text)}
							key={text}
						>
							{text}
						</InButton>
					);
				})}
			</div>
		</div>
	);
};
