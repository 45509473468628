import {PlanProps} from './types';
import cn from 'classnames';

const getPeriodText = (interval: 'month' | 'year' | 'week') => {
	if (interval === 'month') {
		return 'Monthly';
	} else if (interval === 'year') {
		return 'Yearly';
	} else if (interval === 'week') {
		return 'Weekly';
	}
};

export const PWPaymentPlan = ({
	priceBottom = 'per year',
	onSelect,
	special = false,
	price,
	isSelected,
	discount = 0,
}: PlanProps) => (
	<div
		className={cn('paywall-modal__price', {
			'paywall-modal__price_selected': isSelected,
		})}
		onClick={() => onSelect(price)}
	>
		<p className="paywall-modal__price-title">
			{getPeriodText(price.interval)}
		</p>
		<div className="paywall-modal__price_prices">
			<p className="paywall-modal__price-monthly">
				${price.dailyAmount} per day
			</p>
			<p className="paywall-modal__price-yearly">
				Billed ${price.amount}/{price.interval}
			</p>
		</div>
		{special && (
			<div className="paywall-modal__badge">
				<div className="paywall-modal__text-wrapper">Most popular</div>
			</div>
		)}
		{!!discount && (
			<div className="paywall-modal__badge">
				<div className="paywall-modal__text-wrapper">{discount}% off</div>
			</div>
		)}
	</div>
);
