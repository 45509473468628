import {GeneratePhotosDataType} from '../../../api/types';
import {GeneratePhotosSelector} from './GeneratePhotosSelector';
import styles from './GeneratePhotos.module.css';
import photosImg from './images/photos.svg';
import cn from 'classnames';

type GeneratePhotosProps = {
	value: GeneratePhotosDataType;
	onChange: (value: GeneratePhotosDataType) => void;
};

export const GeneratePhotos = ({value, onChange}: GeneratePhotosProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.row}>
				<img src={photosImg} alt="generate photos" className={styles.lock} />
				<p className={cn(styles.text, styles.textWithMargin)}>
					Generate photos
				</p>
				<GeneratePhotosSelector value={value} onSelect={onChange} />
			</div>
		</div>
	);
};
