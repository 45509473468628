import {TextArea} from '../../../common-lib/src/components/TextArea/TextArea';
import {BOT_MAX_LENGTH, DialogType} from '../CreateBotMobile/utils';
import styles from './BotifyTextArea.module.css';
import cn from 'classnames';

type Props = {
	showError?: boolean;
	onChange: (data: string) => void;
	value: string;
	onCheck: (values: string) => void;
	placeholder: string;
	disabled?: boolean;
	className?: string;
	type?: DialogType;
	maxLength?: number;
};

export const BotifyTextArea = ({
	showError,
	onChange,
	value,
	onCheck,
	placeholder,
	disabled,
	className,
	type,
	maxLength,
}: Props) => {
	const max = maxLength || (type && BOT_MAX_LENGTH[type]) || 10000;

	return (
		<>
			<TextArea
				maxLength={max}
				onChange={onChange}
				className={cn(
					'edit-bot__textarea edit-bot__textarea_description',
					styles.textArea,
					className,
					{[styles.error]: showError}
				)}
				value={value}
				placeholder={placeholder}
				onBlur={() => onCheck(value)}
				disabled={disabled}
			/>
			{showError && (
				<div className={styles.errorText}>
					There was detected inappropriate content. Please, change the text.
				</div>
			)}
		</>
	);
};
