import {useContext, useEffect, useState} from 'react';
import {AppContext, VISITED_CHAD_ID} from '../../App';
import styles from './EditTags.module.css';
import {CategoryData} from '../../api/types';
import {EditTagsProps} from './EditTags.types';

export const EditTagsInner = ({
	selectedTags,
	onTagsChange,
	onClose,
	onInnerTagsChange,
}: EditTagsProps) => {
	const {categories} = useContext(AppContext);
	const [selected, setSelected] = useState<CategoryData[]>(selectedTags);

	const handleTagClick = (tag: CategoryData) => {
		const isSelected = selected.find((t) => t.id === tag.id);
		if (isSelected) {
			setSelected(selected.filter((t) => t.id !== tag.id));
		} else {
			if (selected.length >= 3) {
				setSelected([...selected.slice(1), tag]);
			} else {
				setSelected([...selected, tag]);
			}
		}
	};

	useEffect(() => {
		onInnerTagsChange?.(selected);
	}, [selected]);

	const handleDone = () => {
		onTagsChange(selected);
		onClose();
	};

	const avaialbleTags = Object.values(categories).filter(
		(category) => !category.hideInSwitch && category.data.id !== VISITED_CHAD_ID
	);

	return (
		<div>
			<div className={styles.tagsList}>
				{avaialbleTags.map((category) => {
					const isSelected = selected.find((t) => t.id === category.data.id);
					return (
						<div
							key={category.data.id}
							className={`${styles.tag} ${isSelected ? styles.selected : ''}`}
							onClick={() => handleTagClick(category.data)}
						>
							{category.data.attributes.name}
						</div>
					);
				})}
			</div>
			<button className={styles.doneButton} onClick={handleDone}>
				Done
			</button>
		</div>
	);
};
