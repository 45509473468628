import {useState, useRef, useEffect} from 'react';
import styles from './Appearance.module.css';
import cn from 'classnames';
import {SelectPhotoBtn} from '../SelectPhotoBtn/SelectPhotoBtn';
import {BotifyTextArea} from '../../BotifyTextArea/BotifyTextArea';
import avatarPlaceholder from './images/placeholder.svg';
import {FIELD_PLACEHOLDERS} from '../../CreateBotMobile/constants';
import {ChatPhotoStyle} from '../../../../common-lib/src/api/Api.types';
import {DialogMobile} from '../../CreateBotMobile/TextAreaDialog/TextAreaDialog';
import {Modal} from '@mui/material';
import {AiAvatarMobile} from '../../AiAvatar/mobile/AiAvatarMobile';
import {
	CloseModalBtn,
	InButton,
	Spinner,
} from '../../../../common-lib/src/components';
import {AiAvatar} from '../../AiAvatar/AiAvatar';

export type AppearanceProps = {
	appearance: string;
	onChangeAppearance: (value: string) => void;
	onCheckAppearance: (value: string) => Promise<boolean | undefined>;
	photo: string | null;
	onPhotoChange: (
		file: File | null,
		value: string | null,
		style: ChatPhotoStyle
	) => void;
	disabled?: boolean;
	onChoosePhotoClick: () => void;
	isMobile: boolean;
	open: boolean;
	onClose: () => void;
	loading: boolean;
	hasPhotoError: boolean;
	hasAppearanceError: boolean;
};

export type AppearanceInnerProps = AppearanceProps & {
	openAiAvatar: boolean;
	setOpenAiAvatar: (open: boolean) => void;
};

export const Appearance = (props: AppearanceProps) => {
	const [openAiAvatar, setOpenAiAvatar] = useState(false);
	const handleClose = async () => {
		const result = await props.onCheckAppearance(props.appearance);
		if (result) {
			setOpenAiAvatar(false);
			props.onClose();
		}
	};

	if (props.isMobile) {
		return (
			<DialogMobile
				title="Appearance"
				openned={props.open}
				onClose={handleClose}
			>
				<AppearanceInner
					{...props}
					openAiAvatar={openAiAvatar}
					setOpenAiAvatar={setOpenAiAvatar}
					onClose={handleClose}
				/>
			</DialogMobile>
		);
	}

	return (
		<Modal open={props.open} onClose={handleClose}>
			<div className={styles.modal}>
				<div className={styles.modalContainer}>
					<h2 className={styles.title}>Appearance</h2>
					<AppearanceInner
						{...props}
						openAiAvatar={openAiAvatar}
						setOpenAiAvatar={setOpenAiAvatar}
						onClose={handleClose}
					/>
				</div>
				<CloseModalBtn onClose={handleClose} />
			</div>
		</Modal>
	);
};

export const AppearanceInner = ({
	appearance,
	onChangeAppearance,
	onCheckAppearance,
	photo,
	onPhotoChange,
	disabled,
	onChoosePhotoClick,
	openAiAvatar,
	setOpenAiAvatar,
	loading,
	hasPhotoError,
	hasAppearanceError,
	onClose,
	isMobile,
}: AppearanceInnerProps) => {
	const [selectedPhoto, setSelectedPhoto] = useState<string | null>(photo);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handlePhotoSelect = (type: 'gallery' | 'ai') => {
		if (type === 'gallery' && fileInputRef.current) {
			onChoosePhotoClick();
		} else if (type === 'ai') {
			setOpenAiAvatar(true);
		}
	};

	useEffect(() => {
		setSelectedPhoto(photo);
	}, [photo]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result as string;
				setSelectedPhoto(base64String);
				// onPhotoChange(base64String);
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<div className={styles.container}>
			<div
				className={cn(styles.photoContainer, {
					[styles.photoContainerError]: hasPhotoError,
				})}
			>
				<img
					src={selectedPhoto || avatarPlaceholder}
					alt="appearance"
					className={styles.photo}
				/>
				<SelectPhotoBtn onSelect={handlePhotoSelect} />
				<input
					type="file"
					ref={fileInputRef}
					className={styles.fileInput}
					accept="image/*"
					onChange={handleFileChange}
				/>
			</div>
			<BotifyTextArea
				value={appearance}
				onChange={onChangeAppearance}
				onCheck={onCheckAppearance}
				placeholder={FIELD_PLACEHOLDERS.appearance}
				disabled={disabled}
				className={styles.textArea}
				showError={hasAppearanceError}
			/>
			{openAiAvatar && isMobile && (
				<AiAvatarMobile
					openned={openAiAvatar}
					onClose={() => setOpenAiAvatar(false)}
					onChange={onPhotoChange}
					appearance={appearance}
					onChangeAppearance={onChangeAppearance}
				/>
			)}
			{openAiAvatar && !isMobile && (
				<AiAvatar
					openned={openAiAvatar}
					onClose={() => setOpenAiAvatar(false)}
					onChange={onPhotoChange}
					appearance={appearance}
					onChangeAppearance={onChangeAppearance}
				/>
			)}
			{loading && <Spinner withLayout />}
			<InButton
				id="continue-button"
				onClick={onClose}
				isFilled
				className={styles.continueButton}
			>
				Continue
			</InButton>
		</div>
	);
};
