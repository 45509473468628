import './VoiceSelectorRow.css';
import {useVoiceSelector} from './useVoiceSelector';
import arrowImg from '../images/arrow.svg';
import arrowDark from '../images/arrow-dark.svg';
import {useEffect, useState} from 'react';
import {VoiceRow} from '../../VoiceRow/VoiceRow';
import {DoubleSwitcher} from '../../DoubleSwitcher/DoubleSwitcher';
import cn from 'classnames';
import wave from '../images/wave2.svg';
import {VoiceDataType} from '../../../data/voicesData.types';

type VoiceSelectorProps = {
	onSelect: (voice: VoiceDataType) => void;
	selectedVoice: VoiceDataType | null;
	withIcon?: boolean;
	position?: 'top' | 'bottom';
	voices: VoiceDataType[];
};

export const VoiceSelectorRow = ({
	onSelect,
	selectedVoice,
	withIcon = false,
	position = 'bottom',
	voices,
}: VoiceSelectorProps) => {
	const [opened, setOpened] = useState(false);
	const {playing, setPlaying} = useVoiceSelector();
	const [isFemine, setIsFemine] = useState<boolean>(
		selectedVoice?.isFemale || true
	);
	const [isForceChanged, setIsForceChanged] = useState<boolean>(false);
	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!(e.target as HTMLElement).closest('.voice-selector-row__container')) {
			setOpened(!opened);
		}
	};

	const handleClose = (e: MouseEvent) => {
		if (
			!(e.target as HTMLElement)?.closest('.voice-selector-row') &&
			!(e.target as HTMLElement)?.classList?.contains('voice-select')
		) {
			setOpened(false);
		}
	};

	useEffect(() => {
		if (opened) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, [opened]);

	useEffect(() => {
		if (selectedVoice) {
			setIsFemine(selectedVoice.isFemale);
		}
	}, [selectedVoice]);

	useEffect(() => {
		if (isForceChanged) {
			onSelect(
				voices.find((voice) => voice.isFemale === isFemine) as VoiceDataType
			);
		}
	}, [isFemine]);

	const text = withIcon ? 'Voice' : 'Voice:';

	return (
		<div className="voice-selector-row" onClick={handleClick}>
			{withIcon && (
				<img src={wave} alt="wave" className="voice-selector-row__icon" />
			)}
			<span className="voice-selecotr-row__label">{text}</span>
			<div className="voice-selector-row__name-container">
				<p
					className={cn(
						'voice-selector-row__name',
						withIcon ? 'voice-selector-row__name_right' : ''
					)}
				>
					{selectedVoice?.name}
				</p>
				<img
					src={withIcon ? arrowDark : arrowImg}
					alt="arrow"
					className={cn(
						`voice-selector-row__arrow`,
						opened ? 'voice-selector-row__arrow_revert' : '',
						withIcon ? 'voice-selector-row__arrow_right' : ''
					)}
				/>
			</div>
			{opened && (
				<div
					className={cn(
						'voice-selector-row__container',
						position === 'top'
							? 'voice-selector-row__container_top'
							: 'voice-selector-row__container_bottom'
					)}
				>
					<DoubleSwitcher
						left="Femine"
						right="Masculine"
						isLeft={isFemine}
						onSwitch={(isLeft) => {
							setIsFemine(isLeft);
							setIsForceChanged(true);
						}}
						className="voice-type-switcher"
					/>
					{voices
						.filter((voice) => voice.isFemale === isFemine)
						.map((voice) => {
							return (
								<VoiceRow
									key={voice.name}
									voice={voice}
									selected={selectedVoice?.name === voice.name}
									onSelect={() => onSelect(voice)}
									isPlaying={playing?.name === voice.name}
									onPlay={setPlaying}
								/>
							);
						})}
				</div>
			)}
		</div>
	);
};
