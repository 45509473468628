import {CategoryAndBots} from '../../api/apiHelper';
import {MainContainer} from '../Main/MainContainer';
import {TilesPlate} from '../TilesRow/TilesRow';
import {memo, useContext, useEffect, useState} from 'react';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {BotData} from '../../api/types';
import {webApi} from '../../api/webApi';
import {webEventsApi} from '../../amplitude/webEvents';
import {CategoriesSwitch} from '../CategoriesSwitch/CategoriesSwitch';
import {
	AppContext,
	MAIN_CHAT_ID,
	NEW_CATEGORY_NAME,
	PERIOD_CATEGORIES,
} from '../../App';
import styles from './styles.Category.module.css';
import {createCategoryPageJsonLd} from '../../utils';
import {NewCategoryScreen} from './NewCategory/NewCategory';
import {GetAccessBtn} from '../GetAccessBtn/GetAccessBtn';
import {isLastPage, startPage} from './Category.utils';
import {CategoryV2Inner} from './CategoryV2/CategoryV2';

export type CategoryProps = {
	category: CategoryAndBots;
	isV2?: boolean;
	showRandomTile?: boolean;
};

export const CategoryScreen = ({
	category,
	isV2 = false,
	showRandomTile = false,
}: CategoryProps) => {
	const isNew = category.data.attributes.name === NEW_CATEGORY_NAME;
	if (isNew) {
		return <NewCategoryScreen category={category} />;
	}
	return (
		<MemoCategoryScreenInner
			category={category}
			isV2={isV2}
			showRandomTile={showRandomTile}
		/>
	);
};

type HeaderContentProps = {
	isMobile: boolean;
	showBanner?: boolean;
	showUseCases?: boolean;
};

export const getHeaderContent = ({isMobile}: HeaderContentProps) => {
	const commonComponents = (
		<>
			<CategoriesSwitch />
		</>
	);

	return isMobile ? (
		<div className={styles.header}>
			<GetAccessBtn className={styles.getAccessBtn} />
			{commonComponents}
		</div>
	) : (
		commonComponents
	);
};

export const useCategorySceen = (category: CategoryAndBots) => {
	const isMain = category.data.id === MAIN_CHAT_ID;
	const isNew = category.data.attributes.name === NEW_CATEGORY_NAME;
	const isPeriod = PERIOD_CATEGORIES.includes(category.data.attributes.slug);
	const isSpecialCategory = isMain || isNew || isPeriod;

	const {data} = category;
	const [isLoading, setIsLoading] = useState(true);
	const [page, setPage] = useState(startPage);
	const [isEnd, setIsEnd] = useState(false);
	const [elRef, setElRef] = useState<HTMLDivElement | null>(null);
	const [bots, setBots] = useState<BotData[]>([]);
	const {isMobile} = useContext(AppContext);

	const fetchData = async (page: number) => {
		try {
			setIsLoading(true);
			if (page === startPage) {
				setBots([]);
			}
			const {data: botsData, meta} = isPeriod
				? await webApi.getByLikePeriod(category.data.attributes.slug, page)
				: await webApi.getByTag(data.id.toString(), page, isMobile ? 8 : 14);
			if (!botsData.length) {
				setIsEnd(true);
				return;
			}

			setIsEnd(isLastPage(meta));
			if (page === startPage) {
				setBots(botsData);
			} else {
				const botsIdsSet = new Set(bots.map((bot) => bot.id));
				const filteredByDuplicates = botsData.filter(
					(bot) => !botsIdsSet.has(bot.id)
				);
				setBots((prev) => {
					const newVal = [...prev, ...filteredByDuplicates];
					const uniqIds = new Set();
					const recreated = newVal.filter((bot) => {
						if (uniqIds.has(bot.id)) {
							return false;
						}
						uniqIds.add(bot.id);
						return true;
					});
					return recreated;
				});
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (bots.length && !isSpecialCategory) {
			webEventsApi.categoryDetailsLoaded({
				category_id: data.id,
				category_name: data.attributes.name,
				new_count: bots.length,
			});
		}
	}, [bots]);

	const nextPage = async () => {
		setPage(page + 1);
	};

	useEffect(() => {
		if (page > startPage && !isEnd) {
			fetchData(page);
		}
	}, [page]);

	useEffect(() => {
		if (!bots.length) {
			return;
		}
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					nextPage();
				}
			},
			{threshold: 1}
		);

		if (elRef) {
			observer.observe(elRef);
		}

		return () => {
			if (elRef) {
				observer.unobserve(elRef);
			}
		};
	}, [elRef]);

	useEffect(() => {
		setPage(startPage);
		fetchData(startPage);

		if (category) {
			if (isMain) {
				webEventsApi.exploreAppear();
			} else {
				if (isSpecialCategory) {
					return;
				}
				webEventsApi.categoryAppear({
					category_id: data.id,
					category_name: data.attributes.name,
				});
			}
		}
	}, [category]);

	useEffect(() => {
		if (bots.length) {
			createCategoryPageJsonLd(
				data.attributes.name,
				bots.map((bot) => ({
					name: bot.attributes.name,
					url: `https://botify.ai/bot_${bot.id}/chat`,
				}))
			);
		}
	}, [category, bots]);

	return {
		bots,
		isLoading,
		isEnd,
		elRef,
		setElRef,
		page,
		setPage,
		nextPage,
		isMobile,
		isPeriod,
		isMain,
		isSpecialCategory,
	};
};

const CategoryScreenInner = ({
	category,
	isV2 = false,
	showRandomTile = false,
}: CategoryProps) => {
	const {bots, isLoading, isEnd, setElRef, isMobile, isPeriod} =
		useCategorySceen(category);

	return (
		<MainContainer
			headerContent={getHeaderContent({
				isMobile,
				showBanner: isV2,
				showUseCases: isV2,
			})}
		>
			{isV2 && !isPeriod ? (
				<CategoryV2Inner
					category={category}
					bots={bots}
					showRandomTile={showRandomTile}
				/>
			) : (
				<TilesPlate categoryData={category} bots={bots} withRate={isPeriod} />
			)}
			{isLoading ? <Spinner withLayout={false} noTopMargin /> : null}
			{!!bots.length && !isEnd && !isLoading && <div ref={setElRef}></div>}
		</MainContainer>
	);
};

const MemoCategoryScreenInner = memo(CategoryScreenInner, (prev, next) => {
	return prev.category.data.id === next.category.data.id;
});
