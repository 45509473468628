import {MessageVote} from '../components/ChatCommon/ChatMain/ChatMain.types';
import {MediaResponse, Message} from './Api';

export type BotStatus = 'active' | 'muted' | 'inactive' | 'deleted';

export const enum BotType {
	REGULAR = 'regular',
	TALKING_HEADS = 'talking_heads',
}

export type AnimationPipeline = 'high_speed' | 'high_quality' | 'optimal';

export type ShortBotData = {
	name: string;
	id: string;
	messagesQty: number;
	status: BotStatus;
	photoUrl?: string;
	userPaid: boolean;
	type: BotType;
	apiKey?: string;
	videoUrl?: string;
};

export const BOT_FEATURES = [
	'Security',
	'Hate Detect',
	'Remember Me',
	'Empty 1',
	'Empty 2',
	'Empty 3',
] as const;

export type BOT_FEATURE = (typeof BOT_FEATURES)[number];

export type BotData = {
	name: string;
	id: string;
	status: BotStatus;
	photoUrl?: string;
	videoUrl?: string;
	greeting?: string;
	voice: string;
	features: BOT_FEATURE[];
	persona_facts: string[];
	type: BotType;
	animation_pipeline?: AnimationPipeline;
};

export type VoteMessageReqBody = {
	response: string;
	context: Message[];
	reaction: MessageVote;
	contains_media: boolean;
	bot_name: string;
	bot_pronoun?: string;
	strapi_bot_id: string;
	bot_description: string | null;
	bot_appearance: string | null;
	split: string;
	prev_responses?: string[];
	reaction_description?: string;
	media_url: string | null;
	user_name?: string;
	user_pronoun?: string;
};

export type ReactionMessageReqBody = {
	context: Message[];
	prev_context: Message[];
	contains_media: boolean;
	media_url?: string;
	bot_name: string;
	bot_pronoun?: string;
	bot_description?: string;
	bot_appearance?: string;
	strapi_bot_id: string;
	user_name?: string;
	user_pronoun?: string;
	user_id: string;
	split: string;
};

export type AccessLevel = 'basic' | 'premium' | 'ultra';

export type LlmData = {
	id: string;
	name: string;
	description: string;
	access_level: AccessLevel;
	logo_url: string;
	available?: boolean;
};

export type GetLlmListReq = {
	user_id: string;
	access_level: AccessLevel;
};

export type ChatPhotoStyle = 'realistic' | 'anime' | null;

export type GetContextualImageResponse = {
	media_url: string;
	media_type: 'image' | 'video';
	media_id: string;
	media_resolution: [number, number] | null;
	prompt: string;
	is_nsfw: boolean;
};

export type ChatV4Response = {
	request_id: string;
	split: string;
	analytics_metadata: CV4RAnalyticsmetadata;
	responses: CV4RMessage[];
};

export type CV4RMessage = {
	response: string;
	output_module: string;
	exp_name: string;
	media_response: MediaResponse | null;
	base64_audio: null;
};

export type CV4RAnalyticsmetadata = {
	is_user_msg_sexting: boolean;
	contains_photo_request: boolean;
};

export type AutoCompleteBotInfo = {
	name?: string;
	description?: string;
	appearance?: string;
	bio?: string;
	greeting?: string;
	pronoun?: string;
	message_examples?: string[];
	instruction?: string;
};
