import {MobileDialog} from '../../../CreateBot/CreateBotMobile/MobileDialog/MobileDialog';
import {
	ChatPersonaAddButton,
	ChatPersonaHeaderBlock,
	ChatPersonaProps,
	PairTooltip,
} from './ChatPersonaModal';
import {useChatPersona} from './useChatPersona';
import styles from './ChatPersonaModal.module.css';
import {Button, ButtonGroup} from '@mui/material';
import cn from 'classnames';
import {AvatarSelector} from '../../../CreateBot/AvatarSelect/AvatarSelector';
import {AiAvatarMobile} from '../../../CreateBot/AiAvatar/mobile/AiAvatarMobile';
import {Spinner} from '../../../../common-lib/src/components';
import {EditTextField} from '../../../CreateBot/EditTextField';

export const ChatPersonaDialog: React.FC<ChatPersonaProps> = (props) => {
	const {open, onClose} = props;
	const {
		name,
		pronoun,
		personality,
		handlePronounChange,
		handleSubmit,
		setName,
		setPersonality,
		convertUserPronounToPronoun,
		disabled,
		isAIPopupOpen,
		setIsAIPopupOpen,
		handleImageChange,
		image,
		showPairTooltip,
		isLoading,
	} = useChatPersona(props);

	const convertedPronoun = convertUserPronounToPronoun(pronoun);

	return (
		<MobileDialog openned={open} onClose={onClose} title="Chat persona">
			{isLoading && <Spinner withLayout />}
			<ChatPersonaHeaderBlock />
			<div className={styles.avatarContainer}>
				<AvatarSelector
					showError={false}
					onGenerateClick={() => setIsAIPopupOpen(true)}
					value={image}
					onChange={handleImageChange}
					loading={false}
					avatarData={null}
					className={styles.avatarSelector}
				/>
				{showPairTooltip && <PairTooltip />}
			</div>
			<EditTextField
				value={name}
				onChange={setName}
				placeholder="Name*"
				className={styles.personality}
				oneLine
			/>
			<div className={styles.mobilePronounContainer}>
				<ButtonGroup className={cn(styles.pronoun)}>
					<Button
						onClick={() => handlePronounChange('N/A')}
						className={cn(styles.btn, {
							[styles.btnSelected]: convertedPronoun === 'N/A',
						})}
					>
						N/A
					</Button>
					<Button
						onClick={() => handlePronounChange('He')}
						className={cn(styles.btn, {
							[styles.btnSelected]: convertedPronoun === 'He',
						})}
					>
						He
					</Button>
					<Button
						onClick={() => handlePronounChange('She')}
						className={cn(styles.btn, {
							[styles.btnSelected]: convertedPronoun === 'She',
						})}
					>
						She
					</Button>
					<Button
						onClick={() => handlePronounChange('They')}
						className={cn(styles.btn, {
							[styles.btnSelected]: convertedPronoun === 'They',
						})}
					>
						They
					</Button>
				</ButtonGroup>
			</div>
			<EditTextField
				value={personality}
				onChange={setPersonality}
				title="Personality"
				placeholder="Share some details of your personality and background. Which preferences do you have? What makes you unique? Add details to make chats more personal"
				className={styles.personality}
			/>
			<ChatPersonaAddButton onSubmit={handleSubmit} disabled={disabled} />
			<AiAvatarMobile
				openned={isAIPopupOpen}
				onClose={() => setIsAIPopupOpen(false)}
				onChange={handleImageChange}
				oneStyle
			/>
		</MobileDialog>
	);
};
