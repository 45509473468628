import {Dialog, Slide} from '@mui/material';
import styles from './CreateBot.module.css';
import {TransitionProps} from 'notistack';
import React, {useContext, useState} from 'react';
import backImg from './images/back.svg';
import cn from 'classnames';
import {NamePronoun, Pronoun} from './NamePronoun/NamePronoun';
import {VoiceSelectorRow} from '../../common-lib/src/components/Selectors/VoiceSelectorRow/VoiceSelectorRow';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {PublicBot} from './PublicBot/PublicBot';
import {AiAvatar} from './AiAvatar/AiAvatar';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {AppContext} from '../../App';
import {CreateBotMobile} from './CreateBotMobile/CreateBotMobile';
import {BotData, GeneratePhotosDataType} from '../../api/types';
import {
	EditField,
	ExampleMessagesField,
} from './CreateBotMobile/EditField/EditField';
import {BOT_MAX_LENGTH, DialogType} from './CreateBotMobile/utils';
import {GeneratePhotos} from './GeneratePhotosSelector/GeneratePhotos';
import {TagsSection} from './CreateBotMobile/PublicProfileDialog/PublicProfileDialog';
import {EditTagsDesktop} from '../EditTags/EditTagsDesktop';
import {AvatarSelectNew} from './AvatarSelectNew/AvatarSelectNew/AvatarSelectNew';
import {AutocompleteView} from './AutocompleteView/AutocompleteView';
import {useCreateBotPopup} from './useCreateBot';

type Props = {
	openned: boolean;
	onClose: () => void;
	bot: BotData | null;
	botProps: BotProps;
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const getPronoun = (pronoun: Pronoun) => {
	switch (pronoun) {
		case 'He':
			return 'he/him';
		case 'She':
			return 'she/her';
		case 'They':
			return 'they/them';
		case 'N/A':
			return null;
	}
};

export const getGeneratePhotosByChatPhotosType = (
	chatPhotosType: GeneratePhotosDataType
): boolean => {
	if (chatPhotosType === 'disabled') {
		return false;
	}

	return true;
};

export type Errors = {
	[key in DialogType]?: boolean;
} & {
	photo?: boolean;
};

export type BotProps = ReturnType<typeof useCreateBotPopup>;

const CreateBotDesktop = ({openned, onClose, bot, botProps}: Props) => {
	const {
		pronoun,
		setPronoun,
		voice,
		setVoice,
		isPublic,
		setIsPublic,
		isAIPopupOpen,
		setIsAIPopupOpen,
		image,
		handleImageChange,
		isAllFilled,
		handleSaveClick,
		isLoading,
		avatarData,
		errors,
		hasErrors,
		formFields,
		chatPhotosType,
		handleChangeChatPhotosType,
		isCheckLoading,
		voices,
		addExampleHook,
		tags,
		handleTagsChange,
		isAutocompleteOpened,
		handleAutocompleteSubmit,
		shouldOpenAvatarSelect,
		handleAutocompleteClose,
	} = botProps;

	const [isTagsDialogOpenned, setIsTagsDialogOpenned] = useState(false);

	return (
		<Dialog
			fullScreen
			open={openned}
			onClose={onClose}
			TransitionComponent={Transition}
			sx={{
				height: '100dvh',
				backgroundColor: 'black',
				boxSizing: 'border-box',
				width: '100%',
			}}
			className={styles.dialog}
		>
			<div className={styles.container}>
				<div className={styles.header}>
					<img
						src={backImg}
						alt="back"
						onClick={onClose}
						className={styles.back}
					/>
					<h2 className={styles.title}>
						{bot ? 'Edit AI Character' : 'Create AI Character'}
					</h2>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Avatar</h3>
					<div className={cn(styles.blockContent, styles.avatarBlock)}>
						<AvatarSelectNew
							value={image}
							onImageChange={handleImageChange}
							hasPhotoError={!!errors?.photo}
							hasAppearanceError={!!errors?.appearance}
							loading={isCheckLoading}
							avatarData={avatarData}
							appearance={formFields.appearance.value}
							onAppearanceChange={formFields.appearance.changeHandler}
							onAppearanceCheck={formFields.appearance.checkHandler}
							disabled={(hasErrors && !errors?.appearance) || isCheckLoading}
							forceOpen={shouldOpenAvatarSelect}
						/>
					</div>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Name & Pronouns</h3>
					<div className={styles.blockContent}>
						<NamePronoun
							name={formFields.name.value}
							onChangeName={formFields.name.changeHandler}
							onChangePronoun={setPronoun}
							pronoun={pronoun}
							showError={errors?.name}
							onCheck={formFields.name.checkHandler}
							disabled={(hasErrors && !errors?.name) || isCheckLoading}
						/>
					</div>
				</div>
				<EditField
					type="description"
					value={formFields.description.value}
					onChange={formFields.description.changeHandler}
					onCheck={formFields.description.checkHandler}
					hasError={!!errors?.description}
					disabled={(hasErrors && !errors?.description) || isCheckLoading}
				/>
				<EditField
					type="instruction"
					value={formFields.instruction.value}
					onChange={formFields.instruction.changeHandler}
					onCheck={formFields.instruction.checkHandler}
					hasError={!!errors?.instruction}
					disabled={(hasErrors && !errors?.instruction) || isCheckLoading}
				/>
				<EditField
					type="greeting"
					value={formFields.greeting.value}
					onChange={formFields.greeting.changeHandler}
					onCheck={formFields.greeting.checkHandler}
					hasError={!!errors?.greeting}
					disabled={(hasErrors && !errors?.greeting) || isCheckLoading}
				/>
				<ExampleMessagesField
					value={formFields.exampleMessages.value}
					onChange={formFields.exampleMessages.changeHandler}
					onCheck={formFields.exampleMessages.checkHandler}
					hasError={!!errors?.exampleMessages}
					disabled={
						(hasErrors && !errors?.exampleMessages) ||
						isCheckLoading ||
						formFields.exampleMessages.value.length >=
							BOT_MAX_LENGTH.exampleMessages
					}
					open={addExampleHook.openAEM}
					loading={false} // TODO: add loading sklv
				/>
				<div className={styles.block} style={{marginTop: '10px'}}>
					<div className={styles.blockContent}>
						<div className={styles.settingsRow}>
							<VoiceSelectorRow
								position="top"
								onSelect={setVoice}
								selectedVoice={voice}
								withIcon
								voices={voices}
							/>
						</div>
					</div>
				</div>
				<div className={styles.block} style={{marginTop: '10px'}}>
					<div className={styles.blockContent}>
						<GeneratePhotos
							value={chatPhotosType}
							onChange={handleChangeChatPhotosType}
						/>
					</div>
				</div>
				<div className={styles.block} style={{marginTop: '10px'}}>
					<div className={styles.blockContent}>
						<PublicBot value={isPublic} onChange={setIsPublic} isSwitch>
							<div className={styles.bioBlock}>
								<EditField
									type="bio"
									value={formFields.bio.value}
									onChange={formFields.bio.changeHandler}
									onCheck={formFields.bio.checkHandler}
									hasError={!!errors?.bio}
									disabled={(hasErrors && !errors?.bio) || isCheckLoading}
									injected
								/>
								<TagsSection
									tags={tags}
									onOpenTagsDialog={() => setIsTagsDialogOpenned(true)}
								/>
							</div>
						</PublicBot>
					</div>
				</div>
				<InButton
					id="create-character-button"
					isFilled
					className={styles.createButton}
					isDisabled={!isAllFilled || hasErrors}
					onClick={handleSaveClick}
				>
					{bot ? 'Edit AI Character' : 'Create AI Character'}
				</InButton>
			</div>
			<AiAvatar
				openned={isAIPopupOpen}
				onClose={() => setIsAIPopupOpen(false)}
				onChange={handleImageChange}
			/>
			<EditTagsDesktop
				open={isTagsDialogOpenned}
				onClose={() => setIsTagsDialogOpenned(false)}
				selectedTags={tags}
				onTagsChange={handleTagsChange}
			/>
			{isAutocompleteOpened && (
				<AutocompleteView
					onClose={handleAutocompleteClose}
					onSubmit={handleAutocompleteSubmit}
				/>
			)}
			{isLoading && <Spinner />}
			{addExampleHook.AddExampleModal()}
		</Dialog>
	);
};

export const CreateBotPopup = ({
	openned,
	onClose,
	bot,
}: Omit<Props, 'botProps'>) => {
	const {isMobile} = useContext(AppContext);

	const botProps = useCreateBotPopup({onClose, bot});

	if (isMobile) {
		return (
			<CreateBotMobile
				openned={openned}
				onClose={onClose}
				bot={bot}
				botProps={botProps}
			/>
		);
	}

	return (
		<CreateBotDesktop
			openned={openned}
			onClose={onClose}
			bot={bot}
			botProps={botProps}
		/>
	);
};
