import {useEffect, useState} from 'react';
import styles from './SelectPhotoBtn.module.css';
import editImg from './images/edit.svg';

export type PhotoSelectType = 'gallery' | 'ai';

const photoSelectOptions: {id: PhotoSelectType; label: string}[] = [
	{id: 'gallery', label: 'Choose from gallery'},
	{id: 'ai', label: 'Generate with AI'},
];

type Props = {
	onSelect: (option: PhotoSelectType) => void;
};

export const SelectPhotoBtn = ({onSelect}: Props) => {
	const [opened, setOpened] = useState(false);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!(e.target as HTMLElement)?.closest('#select-photo-btn-container')) {
			setOpened(!opened);
			e.stopPropagation();
		}
	};

	const handleClose = (e: MouseEvent) => {
		if (
			!(e.target as HTMLElement)?.closest(`.${styles.selector}`) &&
			!(e.target as HTMLElement)?.classList?.contains(styles.row)
		) {
			setOpened(false);
		}
	};

	useEffect(() => {
		if (opened) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, [opened]);

	const handleSelect = (
		e: React.MouseEvent<HTMLDivElement>,
		option: PhotoSelectType
	) => {
		e.stopPropagation();
		e.preventDefault();
		onSelect(option);
		setOpened(false);
	};

	return (
		<div className={styles.selector} onClick={handleClick}>
			<img src={editImg} alt="edit" className={styles.edit} />
			{opened && (
				<div className={styles.container} id="select-photo-btn-container">
					{photoSelectOptions.map((option) => {
						return (
							<div
								className={styles.row}
								onClick={(e) => handleSelect(e, option.id)}
								key={option.id}
							>
								<div className={styles.rowName}>
									<p>{option.label}</p>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
