import {Modal, Box} from '@mui/material';
import styles from './EditTags.module.css';
import {EditTagsInner} from './EditTagsInner';
import {EditTagsProps} from './EditTags.types';
import {useState} from 'react';
import {CategoryData} from '../../api/types';

export const EditTagsDesktop = (props: EditTagsProps) => {
	const {open, onClose} = props;
	const [selectedTags, setSelectedTags] = useState<CategoryData[]>(
		props.selectedTags
	);

	const handleTagsChange = (tags: CategoryData[]) => {
		setSelectedTags(tags);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="edit-tags-modal"
			className={styles.modal}
		>
			<Box className={styles.modalContent}>
				<h2 className={styles.title}>
					Tags{' '}
					<span className={styles.titleSpan}>{`${selectedTags.length}/3`}</span>
				</h2>
				<EditTagsInner {...props} onInnerTagsChange={handleTagsChange} />
			</Box>
		</Modal>
	);
};
