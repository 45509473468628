export const nameDescriptionSuggestions = {
	Girlfriend:
		'Trendy TikTok queen with a slay attitude, always snapping selfies in thrifted fits',
	Boyfriend:
		'Chill skater dude with a playlist full of lo-fi beats and a sneaky flirty smirk',
	'Goth Queen':
		'Mysterious vibe-checker in all black, rocking dark lipstick and vintage chokers',
	'Vampire Lord':
		'Brooding night king with sharp fangs, dripping in red velvet and old-school drip',
	'Best Friend':
		"Ride-or-die homie who's always down for late-night Discord hangs and meme spams",
	Waifu:
		'Kawaii dream girl with pastel hair, obsessed with bubble tea and cozy anime nights',
	Furry:
		'Playful fox vibes with a fluffy tail, dropping quirky texts and chaotic energy',
	Werewolf:
		'Rugged moon-howler with ripped jeans, flexing wild energy and a soft side',
	Nurse:
		'Sassy healthcare baddie in pastel scrubs, juggling coffee and saving lives',
	Secretary:
		'Boss-babe energy with a sleek bob, typing 100 WPM while sipping iced lattes',
	Succubus:
		'Seductive shadow queen with a sultry wink, thriving on late-night chaos and charm',
	Demon:
		"Edgy hellspawn with a fiery 'tude, blasting trap beats from the underworld",
	Cyberpunk:
		'Neon-lit rebel with glitchy shades, hacking the system and vibing to synthwave',
	Femboy:
		'Soft boi with a bold streak, slaying in oversized hoodies and glitter nails',
};
