import {useState} from 'react';
import {CategoryData} from '../../api/types';
import {DialogMobile} from '../CreateBot/CreateBotMobile/TextAreaDialog/TextAreaDialog';
import {EditTagsProps} from './EditTags.types';
import {EditTagsInner} from './EditTagsInner';

export const EditTagsMobile = (props: EditTagsProps) => {
	const {open, onClose} = props;
	const [selectedTags, setSelectedTags] = useState<CategoryData[]>(
		props.selectedTags
	);

	return (
		<DialogMobile
			title={`Tags ${selectedTags.length}/3`}
			openned={open}
			onClose={onClose}
		>
			<EditTagsInner {...props} onInnerTagsChange={setSelectedTags} />
		</DialogMobile>
	);
};
