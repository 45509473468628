import {BotifyTextArea} from './BotifyTextArea/BotifyTextArea';
import cn from 'classnames';
import styles from './CreateBot.module.css';

export const EditTextField = ({
	value,
	onChange,
	placeholder,
	title,
	onCheck = () => {},
	hasError = false,
	disabled = false,
	oneLine = false,
	className,
}: {
	value: string;
	onChange: (value: string) => void;
	placeholder: string;
	title?: string;
	onCheck?: (value: string) => void;
	hasError?: boolean;
	disabled?: boolean;
	oneLine?: boolean;
	className?: string;
}) => {
	return (
		<div className={cn(styles.block, className)}>
			{title && <h3 className={styles.blockTitle}>{title}</h3>}
			<div className={styles.blockContent}>
				<BotifyTextArea
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					onCheck={onCheck}
					showError={hasError}
					disabled={disabled}
					className={cn(oneLine && styles.oneLine)}
				/>
			</div>
		</div>
	);
};