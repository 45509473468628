import {Modal} from '@mui/material';
import styles from './AddExample.module.css';
import {useEffect, useState} from 'react';
import {InButton, CloseModalBtn} from '../../../../common-lib/src/components';
import {BotifyTextArea} from '../../BotifyTextArea/BotifyTextArea';
import {FIELD_PLACEHOLDERS} from '../../CreateBotMobile/constants';
import {EXAMPLE_MESSAGE_MAX_LENGTH} from '../../CreateBotMobile/utils';

type Props = {
	openned: boolean;
	onClose: () => void;
	onSubmit: (text: string) => Promise<boolean>;
	loading: boolean;
	hasErrors: boolean;
	resetErrors: () => void;
};

export const AddExample = ({
	openned,
	onClose,
	onSubmit,
	loading,
	hasErrors,
}: Props) => {
	const [value, setValue] = useState('');

	useEffect(() => {
		setValue('');
	}, [openned]);

	const handleSubmit = async () => {
		const isAllowed = await onSubmit(value);
		if (isAllowed) {
			onClose();
		}
	};

	return (
		<Modal open={openned} onClose={onClose}>
			<div className={styles.modal}>
				<div className={styles.container}>
					<h2 className={styles.title}>Add example message</h2>
					<BotifyTextArea
						value={value}
						onChange={setValue}
						placeholder={FIELD_PLACEHOLDERS.exampleMessages}
						onCheck={() => true}
						showError={hasErrors}
						disabled={loading}
						type="exampleMessages"
						className={styles.textArea}
						maxLength={EXAMPLE_MESSAGE_MAX_LENGTH}
					/>
					<InButton
						id="add-example-button"
						onClick={handleSubmit}
						className={styles.btn}
						isFilled
						isDisabled={!value.trim() || loading}
					>
						{loading ? 'Loading...' : 'Add'}
					</InButton>
					<CloseModalBtn onClose={onClose} />
				</div>
			</div>
		</Modal>
	);
};

type AddExampleProps = Pick<
	Props,
	'onSubmit' | 'loading' | 'hasErrors' | 'resetErrors'
>;

export const useAddExample = ({
	onSubmit,
	loading,
	hasErrors,
	resetErrors,
}: AddExampleProps) => {
	const [opened, setOpened] = useState(false);

	const open = () => setOpened(true);
	const close = () => setOpened(false);

	const handleClose = () => {
		close();
		resetErrors();
	};

	const AddExampleModal = () => (
		<AddExample
			openned={opened}
			onClose={handleClose}
			onSubmit={onSubmit}
			loading={loading}
			hasErrors={hasErrors}
			resetErrors={resetErrors}
		/>
	);

	return {openAEM: open, closeAEM: close, AddExampleModal};
};
