import styles from './AvatarSelectNew.module.css';
import cn from 'classnames';
import avatarPlaceholder from '../images/avatarPlaceholder.svg';
import editImg from '../images/edit.svg';
import {useEffect, useRef, useState} from 'react';
import {Appearance} from '../Appearance/Appearance';
import {ChatPhotoStyle} from '../../../../common-lib/src/api/Api.types';

export type AvatarSelectorProps = {
	hasPhotoError: boolean;
	hasAppearanceError: boolean;
	value: string | null;
	onImageChange: (
		file: File | null,
		value: string | null,
		style: ChatPhotoStyle
	) => void;
	loading: boolean;
	avatarData: {
		idle_url?: string;
		image_url: string;
	} | null;
	className?: string;
	appearance: string;
	onAppearanceChange: (value: string) => void;
	onAppearanceCheck: (value: string) => Promise<boolean | undefined>;
	disabled: boolean;
	isMobile?: boolean;
	forceOpen?: boolean;
};

export const useAvatarSelector = ({
	onImageChange,
	avatarData,
}: AvatarSelectorProps) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleAvatarSelect = (file: File) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onload = (readerEvent) => {
			const content = readerEvent?.target?.result;
			onImageChange(file, content as string, 'realistic');
		};
	};

	const handleAvatarEditClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleDrop = (e: any) => {
		e.preventDefault();
		const file = e?.dataTransfer?.files[0];
		if (!file) {
			return;
		}

		if (fileInputRef.current) {
			handleAvatarSelect(file);
		}
	};

	const handleAvatrInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];

		if (!file) {
			return;
		}

		handleAvatarSelect(file);
	};

	const idleAvatar = avatarData?.idle_url ? (
		<video
			autoPlay
			muted={true}
			loop
			playsInline
			className={styles.avatar}
			key={avatarData.idle_url}
		>
			<source src={avatarData.idle_url} type="video/mp4" />
		</video>
	) : null;

	return {
		handleDrop,
		handleAvatarEditClick,
		handleAvatrInputChange,
		idleAvatar,
		fileInputRef,
	};
};

export const AvatarSelectNew = ({
	hasPhotoError,
	hasAppearanceError,
	value,
	onImageChange,
	loading,
	avatarData,
	appearance,
	onAppearanceChange,
	onAppearanceCheck,
	disabled,
	isMobile = false,
	forceOpen = false,
}: AvatarSelectorProps) => {
	const {
		handleAvatrInputChange,
		idleAvatar,
		fileInputRef,
		handleAvatarEditClick,
	} = useAvatarSelector({
		hasPhotoError,
		hasAppearanceError,
		value,
		onImageChange,
		loading,
		avatarData,
		appearance,
		onAppearanceChange,
		onAppearanceCheck,
		disabled,
		isMobile,
	});

	const [openAppearance, setOpenAppearance] = useState(forceOpen);

	useEffect(() => {
		setOpenAppearance(forceOpen);
	}, [forceOpen]);

	return (
		<div className={styles.avatarSelector}>
			<div className="upload-photo-modal__outer-container">
				<div className="upload-photo-modal__inner-container">
					{idleAvatar || (
						<img
							src={value || avatarData?.image_url || avatarPlaceholder}
							alt="avatar"
							className={cn(styles.avatar, {
								[styles.avatarError]: hasPhotoError,
							})}
						/>
					)}
				</div>
				<img
					src={editImg}
					alt="edit"
					className={styles.edit}
					onClick={() => setOpenAppearance(true)}
				/>
				<input
					type="file"
					className={styles.fileInput}
					ref={fileInputRef}
					accept="image/png, image/jpeg"
					onChange={handleAvatrInputChange}
				/>
				<Appearance
					open={openAppearance}
					onClose={() => setOpenAppearance(false)}
					appearance={appearance}
					onChangeAppearance={onAppearanceChange}
					onCheckAppearance={onAppearanceCheck}
					photo={value}
					onPhotoChange={onImageChange}
					onChoosePhotoClick={handleAvatarEditClick}
					isMobile={isMobile}
					loading={loading}
					hasPhotoError={hasPhotoError}
					hasAppearanceError={hasAppearanceError}
				/>
			</div>
		</div>
	);
};
