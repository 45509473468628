import {DialogType} from './utils';

export const FIELD_CHANGE_TITLES: Record<DialogType, string> = {
	description: 'Personality',
	greeting: 'Greeting',
	appearance: 'Appearance',
	exampleMessages: 'Add example',
	instruction: 'Instruction',
	bio: 'Bio',
	name: 'Name',
};

export const FIELD_NAMES: Record<DialogType, string> = {
	...FIELD_CHANGE_TITLES,
	exampleMessages: 'Example Messages',
	bio: 'Bot bio',
};

export const FIELD_PLACEHOLDERS: Record<DialogType, string> = {
	description:
		"Describe details of your bot's personality and backstory. Describe the situation you want them in and the kinds of things they want to talk about",
	greeting:
		'The first message will be sent by the bot when starting a new chat. It’s very important to set the scene and steer future conversation',
	appearance:
		'Describe details of your bot’s look. They will be used in image prompts for photos generated by the bot',
	exampleMessages:
		'The example message serves as a reference for tone, length, and style. Please write it from the perspective of your bot.',
	instruction:
		'Specific instructions on how your bot will behave and how it responds to messages',
	bio: 'A few words to help people pick the bot. Will be displayed in bot listings.',
	name: 'Provide a name for the bot.',
};
