import styles from './PublicBot.module.css';
import lock from './images/lock.svg';
import check from './images/check.svg';
import {Switcher} from '../../../common-lib/src/components/Switcher/Switcher';
import cn from 'classnames';
import {PropsWithChildren} from 'react';

type PublicBotProps = {
	value: boolean;
	onChange: (value: boolean) => void;
	isSwitch?: boolean;
	small?: boolean;
};

export const PublicBot = ({
	value,
	onChange,
	isSwitch = false,
	small = false,
	children,
}: PropsWithChildren<PublicBotProps>) => {
	const ValueChangeComponent = isSwitch ? (
		<Switcher
			className={styles.switch}
			checked={value}
			onChange={() => onChange(!value)}
		/>
	) : (
		<div className={styles.checkbox}>
			{value && <img src={check} alt="check" className={styles.check} />}
		</div>
	);

	return (
		<div className={styles.container}>
			<div className={cn(styles.row, {[styles.rowSmall]: small})}>
				<div className={styles.rowContent} onClick={() => onChange(!value)}>
					<img
						src={lock}
						alt="public bot"
						className={cn(styles.lock, {[styles.lockSmall]: small})}
					/>
					<div className={styles.textContainer}>
						<p className={styles.text}>Public bot</p>
						<p className={styles.note}>
							{value ? 'Anyone can find your bot' : 'Only you can find bot'}
						</p>
					</div>
					{ValueChangeComponent}
				</div>
				{value && children}
			</div>
		</div>
	);
};
