import {ExampleMessage} from '../../CreateBot.types';
import {FIELD_NAMES} from '../../CreateBotMobile/constants';
import {ExampleMessagesInner} from '../../CreateBotMobile/EditField/EditField';
import {DialogMobile} from '../../CreateBotMobile/TextAreaDialog/TextAreaDialog';

type Props = {
	open: boolean;
	onClose: () => void;
	onChange: (value: ExampleMessage[]) => void;
	onOpenAEM: () => void;
	value: ExampleMessage[];
	disabled: boolean;
	error: boolean;
	loading: boolean;
};

export const ExampleMessagesMobile = ({
	open,
	onClose,
	onChange,
	onOpenAEM,
	value,
	disabled,
	error,
	loading,
}: Props) => {
	return (
		<DialogMobile
			title={FIELD_NAMES.exampleMessages}
			openned={open}
			onClose={onClose}
		>
			<ExampleMessagesInner
				value={value}
				onChange={onChange}
				onCheck={() => true}
				hasError={error}
				open={onOpenAEM}
				disabled={disabled}
				loading={loading}
			/>
		</DialogMobile>
	);
};
