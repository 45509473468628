import {useSnackbar} from 'notistack';
import {useContext, useState, useEffect} from 'react';
import {webEventsApi} from '../../amplitude/webEvents';
import {BotData, GeneratePhotosDataType, CategoryData} from '../../api/types';
import {webApi} from '../../api/webApi';
import {AppContext} from '../../App';
import {api} from '../../common-lib/src/api/Api';
import {ChatPhotoStyle, AutoCompleteBotInfo} from '../../common-lib/src/api/Api.types';
import {VoiceDataType} from '../../common-lib/src/data/voicesData.types';
import {convertBase64ToImage} from '../../common-lib/src/utils';
import {Errors, getPronoun, getGeneratePhotosByChatPhotosType} from './CreateBot';
import {FormFields} from './CreateBot.types';
import {convertPronoun, convertVoicesFullDataToVoiceDataType} from './CreateBot.utils';
import {DialogType} from './CreateBotMobile/utils';
import {useAddExample} from './ExampleMessages/AddExample/AddExample';
import {Pronoun} from './NamePronoun/NamePronoun';

export const useCreateBotPopup = ({
	onClose,
	bot,
}: {
	onClose?: () => void;
	bot: BotData | null;
}) => {
	const {user, setIsPaywallModalOpened} = useContext(AppContext);
	const [name, setName] = useState('');
	const [pronoun, setPronoun] = useState<Pronoun>('N/A');

	const [voice, setVoice] = useState<VoiceDataType | null>(null);
	const [voices, setVoices] = useState<VoiceDataType[]>([]);

	const [isPublic, setIsPublic] = useState(true);
	const [isAIPopupOpen, setIsAIPopupOpen] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [file, setFile] = useState<File | null>(null);
	const [chatPhotosType, setChatPhotosType] =
		useState<GeneratePhotosDataType>('disabled');

	const [description, setDescription] = useState('');
	const [appearance, setAppearance] = useState('');
	const [greeting, setGreeting] = useState('');
	const [instruction, setInstruction] = useState('');
	const [exampleMessages, setExampleMessages] = useState<{text: string}[]>([]);
	const [bio, setBio] = useState('');
	const [tags, setTags] = useState<CategoryData[]>([]);

	const [isLoading, setIsLoading] = useState(false);
	const [isCheckLoading, setIsCheckLoading] = useState(false);

	const [isAutocompleteOpened, setIsAutocompleteOpened] = useState(!bot?.id);
	const [shouldOpenAvatarSelect, setShouldOpenAvatarSelect] = useState(false);

	const [isAvatarLoading, setIsAvatarLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const [avatarData, setAvatarData] = useState<{
		idle_url?: string;
		image_url: string;
	} | null>(null);
	const [errors, setErrors] = useState<Errors | null>(null);

	const {categories} = useContext(AppContext);
	const [categoriesData, setCategoriesData] = useState<CategoryData[]>([]);

	useEffect(() => {
		if (categories) {
			setCategoriesData(Object.values(categories).map((c) => c.data));
		}
	}, [categories]);

	const handleTagsChange = (tags: CategoryData[]) => {
		setTags(tags);
	};

	const isAllFilled =
		!!name.trim() &&
		!!description.trim() &&
		!!appearance.trim() &&
		!!greeting.trim() &&
		!!instruction.trim() &&
		avatarData;

	const handleCheck = async (
		field: DialogType,
		value: string | string[] | {text: string}[],
		skipSetErrors = false
	): Promise<boolean> => {
		if (!skipSetErrors) {
			resetErrors(field);
		}

		const isString = typeof value === 'string';
		if (!value || value.length === 0) {
			return true;
		}

		if (isString && !value.trim()) {
			return true;
		}

		const isTextArrayType = (
			a: string | string[] | {text: string}[]
		): a is {text: string}[] => {
			return field === 'exampleMessages';
		};

		const body = {
			bot_name: name || null,
			description: description || null,
			appearance: appearance || null,
			greeting: greeting || null,
			instruction: instruction || null,
			message_examples: exampleMessages.map((m) => m.text) || null,
			bio: bio || null,
		} as Record<string, string | string[]>;

		if (isString) {
			if (field === 'name') {
				body['bot_name'] = value;
			}
			if (field === 'description') {
				body['description'] = value;
			}
			if (field === 'appearance') {
				body['appearance'] = value;
			}
			if (field === 'greeting') {
				body['greeting'] = value;
			}
			if (field === 'instruction') {
				body['instruction'] = value;
			}
			if (field === 'bio') {
				body['bio'] = value;
			}
		}

		if (field === 'exampleMessages' && isTextArrayType(value)) {
			body['message_examples'] = value.map((m) => m.text);
		}

		try {
			setIsCheckLoading(true);
			const {allow_to_create, reason} = await api.checkBot(body);

			if (!allow_to_create && !skipSetErrors) {
				const updatedErrors: Errors = {...errors};
				updatedErrors[field] = true;
				setErrors(updatedErrors);
				enqueueSnackbar(reason, {variant: 'error'});
			}

			return allow_to_create;
		} catch (e) {
			if (e instanceof DOMException && e.name === 'TimeoutError') {
				enqueueSnackbar('Server is taking too long to respond', {
					variant: 'error',
				});
				return true;
			} else {
				console.error(e);
				enqueueSnackbar('Something went wrong', {variant: 'error'});
			}
			return false;
		} finally {
			setIsCheckLoading(false);
		}
	};
	const handleCheckName = (value: string, skipSetErrors?: boolean) =>
		handleCheck('name', value, skipSetErrors);
	const handleCheckDescription = (value: string, skipSetErrors?: boolean) =>
		handleCheck('description', value, skipSetErrors);
	const handleCheckAppearance = (value: string, skipSetErrors?: boolean) =>
		handleCheck('appearance', value, skipSetErrors);
	const handleCheckGreeting = (value: string, skipSetErrors?: boolean) =>
		handleCheck('greeting', value, skipSetErrors);
	const handleCheckInstruction = (value: string, skipSetErrors?: boolean) =>
		handleCheck('instruction', value, skipSetErrors);
	const handleCheckExampleMessages = (
		value: {text: string}[],
		skipSetErrors?: boolean
	) => handleCheck('exampleMessages', value, skipSetErrors);
	const handleCheckBio = (value: string, skipSetErrors?: boolean) =>
		handleCheck('bio', value, skipSetErrors);
	const handleSaveClick = async () => {
		try {
			setIsLoading(true);

			const getTrimmedOrUndefined = (value: string) =>
				value.trim() === '' ? undefined : value.trim();

			const body = {
				name: getTrimmedOrUndefined(name),
				private: !isPublic,
				pronoun: getPronoun(pronoun) || undefined,
				voice: voice?.name || undefined,
				avatarUrl: avatarData?.image_url || undefined,
				idleUrl: avatarData?.idle_url || undefined,
				description: getTrimmedOrUndefined(description),
				appearance: getTrimmedOrUndefined(appearance),
				greeting: getTrimmedOrUndefined(greeting),
				instruction: getTrimmedOrUndefined(instruction),
				generatePhotos: getGeneratePhotosByChatPhotosType(chatPhotosType),
				isSexual: false,
				chatPhotosType,
				bio: getTrimmedOrUndefined(bio),
				tags: {connect: tags.map((t) => t.id)},
				exampleMessages,
			};

			resetErrors('name');

			const {allow_to_create, reason, make_private} = await api.checkBot({
				bot_name: body.name,
				description,
				appearance,
				greeting,
				instruction,
				message_examples: exampleMessages.map((m) => m.text),
				bio,
			});

			body.isSexual = make_private;

			if (!allow_to_create) {
				const updatedErrors: Errors = {...errors};
				updatedErrors.name = true;
				updatedErrors.description = true;
				updatedErrors.appearance = true;
				updatedErrors.greeting = true;
				updatedErrors.instruction = true;
				updatedErrors.bio = true;
				setErrors(updatedErrors);
				setIsLoading(false);
				enqueueSnackbar(reason, {variant: 'error'});
				return;
			}

			const res = bot
				? await webApi.updateBot(bot.id, body)
				: await webApi.createBot(body!);

			if (!bot && res?.data?.attributes) {
				const b = res as {data: BotData};
				webEventsApi.customBotCreated({
					chat_id: b.data.attributes.firebaseChatId,
					user_id: api.getUserId(),
					is_private: b.data.attributes.private,
					idle_available: !!b.data.attributes.idleUrl,
					idle_turned_on: true,
				});
			}

			if (!res?.data?.id) {
				const text = (res?.error?.message || 'Something went wrong')
					.split('\n')
					.map((line: string, i: number) => <div key={i}>{line}</div>);
				enqueueSnackbar(<div>{text}</div>, {
					variant: 'error',
					key: 'create-validation-error',
				});
				return;
			}

			onClose?.();
			window.location.href = `/bot_${res.data.id}/chat`;
		} catch (e) {
			console.log(e);
			enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleAddExample = async (text: string) => {
		const newExampleMessages = [...exampleMessages, {text}];

		const isAllowed = await handleCheckExampleMessages(newExampleMessages);

		if (isAllowed) {
			setExampleMessages(newExampleMessages);
			return true;
		}

		return false;
	};

	const resetErrors = (field: keyof Errors) => {
		if (!errors || !errors[field]) {
			return;
		}
		const newErrors = {...errors};
		delete newErrors[field];
		setErrors(newErrors);
	};

	const addExampleHook = useAddExample({
		onSubmit: handleAddExample,
		loading: isCheckLoading,
		hasErrors: !!errors?.exampleMessages,
		resetErrors: () => resetErrors('exampleMessages'),
	});

	const hasErrors = Object.keys(errors || {}).length > 0;

	useEffect(() => {
		const getAvatarData = async () => {
			setIsAvatarLoading(true);
			try {
				const result = await api.generateAnimation(file!);

				if (result?.message === 'Child detected!') {
					enqueueSnackbar('Child detected! Please upload a different photo', {
						variant: 'error',
					});
					setErrors({
						...(errors || {}),
						photo: true,
					});
				} else if (result?.message) {
					enqueueSnackbar(result.message, {
						variant: 'error',
					});
					setErrors({
						...(errors || {}),
						photo: true,
					});
				} else if (result?.idle_url) {
					resetErrors('photo');
					setAvatarData(result);
				} else if (result?.image_url && result?.is_gallery_available) {
					resetErrors('photo');
					setAvatarData(result);
				} else {
					enqueueSnackbar('Something wrong with avatar generation', {
						variant: 'error',
					});
					setErrors({
						...(errors || {}),
						photo: true,
					});
				}
			} catch (e) {
				console.log(e);
				enqueueSnackbar('Something wrong with avatar generation', {
					variant: 'error',
				});
				setErrors({
					...(errors || {}),
					photo: true,
				});
			} finally {
				setIsAvatarLoading(false);
			}
		};

		if (!file) {
			if (bot) {
				return;
			}
			setAvatarData(null);
		} else {
			getAvatarData();
		}
	}, [file]);

	const handleImageChange = (
		file: File | null,
		value: string | null,
		style: ChatPhotoStyle
	) => {
		setImage(value);
		setFile(file);
		setChatPhotosType(style || 'disabled');
	};

	useEffect(() => {
		if (errors?.name) {
			resetErrors('name');
		}
	}, [name]);

	useEffect(() => {
		if (errors?.description) {
			resetErrors('description');
		}
	}, [description]);

	useEffect(() => {
		if (errors?.appearance) {
			resetErrors('appearance');
		}
	}, [appearance]);

	useEffect(() => {
		if (errors?.greeting) {
			resetErrors('greeting');
		}
	}, [greeting]);

	useEffect(() => {
		if (errors?.instruction) {
			resetErrors('instruction');
		}
	}, [instruction]);

	useEffect(() => {
		if (avatarData && chatPhotosType === 'disabled') {
			setChatPhotosType('realistic');
		}
	}, [avatarData]);

	useEffect(() => {
		if (errors?.bio) {
			resetErrors('bio');
		}
	}, [bio]);

	const handleChangeChatPhotosType = (v: GeneratePhotosDataType) => {
		setChatPhotosType(v);
	};

	const handleChangeIsPublic = (v: boolean) => {
		if (!user?.isPaid) {
			setIsPaywallModalOpened(true);
			return;
		}
		setIsPublic(v);
	};

	useEffect(() => {
		if (!bot || !voices.length) {
			return;
		}

		const {attributes} = bot;
		setName(attributes.name);
		setPronoun(attributes.pronoun ? convertPronoun(attributes.pronoun) : 'N/A');

		const selectedVoice = attributes.voice
			? voices.find(
					(v) => v.name === attributes.voice?.data?.attributes.name
			  ) || voices[0]
			: voices[0];

		setVoice(selectedVoice);
		setIsPublic(!attributes.private);
		setDescription(attributes.description || '');
		setAppearance(attributes.appearance || '');
		setGreeting(attributes.greeting || '');
		setInstruction(attributes.instruction || '');
		setBio(attributes.bio || '');
		setAvatarData({
			idle_url: attributes.idleUrl,
			image_url: attributes.avatarUrl,
		});
		setChatPhotosType(
			attributes.chatPhotosType || attributes.generatePhotos
				? 'realistic'
				: 'disabled'
		);
		const tags: CategoryData[] =
			(attributes.tags?.data
				.map((t) => categoriesData.find((c) => c.id === t.id))
				.filter((t) => t !== undefined) as CategoryData[]) || [];
		setTags(tags);
		setImage(attributes.avatarUrl);
		setExampleMessages(
			attributes.exampleMessages?.map((m) => ({text: m.text})) || []
		);
	}, [bot, voices, categoriesData]);

	useEffect(() => {
		if (voice || !voices.length || bot) {
			return;
		}
		setVoice(voices[0]);
	}, [voices, bot]);

	useEffect(() => {
		const getVoices = async () => {
			const voices = await webApi.getVoices().catch((e) => {
				console.error(e);
				enqueueSnackbar('Failed to load voices', {variant: 'error'});
				return {data: []};
			});
			setVoices(convertVoicesFullDataToVoiceDataType(voices.data));
		};
		getVoices();
	}, []);

	const handleAutocompleteSubmit = (
		data: AutoCompleteBotInfo & {avatar: any}
	) => {
		const {
			name,
			description,
			appearance,
			greeting,
			instruction,
			bio,
			pronoun,
			message_examples,
			avatar,
		} = data;

		const setAvatar = async () => {
			const value = Object.values(avatar);
			if (!value.length || avatar.error || avatar.detail) {
				return;
			}
			const base64_image = value[0] as string;
			const b64image = await convertBase64ToImage({base64_image});
			if (!b64image) {
				return;
			}

			async function blobUrlToFile(blobUrl: string, fileName: string) {
				const response = await fetch(blobUrl);
				const blob = await response.blob();
				const file = new File([blob], fileName, {type: blob.type});
				return file;
			}

			const file = await blobUrlToFile(b64image, 'avatar.png');
			handleImageChange(file, b64image, 'realistic');
			setShouldOpenAvatarSelect(true);
		};

		if (avatar) {
			setAvatar();
		}

		if (name) {
			setName(name);
		}
		if (pronoun) {
			setPronoun(pronoun ? convertPronoun(pronoun) : 'N/A');
		}
		if (description) {
			setDescription(description);
		}
		if (appearance) {
			setAppearance(appearance);
		}
		if (greeting) {
			setGreeting(greeting);
		}
		if (instruction) {
			setInstruction(instruction);
		}
		if (bio) {
			setBio(bio);
		}
		if (message_examples) {
			setExampleMessages(message_examples.map((m) => ({text: m})));
		}
		setIsAutocompleteOpened(false);
	};

	const handleAutocompleteClose = () => {
		setIsAutocompleteOpened(false);
		onClose?.();
	};

	const formFields: FormFields = {
		name: {
			value: name,
			changeHandler: setName,
			checkHandler: handleCheckName,
		},
		description: {
			value: description,
			changeHandler: setDescription,
			checkHandler: handleCheckDescription,
		},
		appearance: {
			value: appearance,
			changeHandler: setAppearance,
			checkHandler: handleCheckAppearance,
		},
		greeting: {
			value: greeting,
			changeHandler: setGreeting,
			checkHandler: handleCheckGreeting,
		},
		instruction: {
			value: instruction,
			changeHandler: setInstruction,
			checkHandler: handleCheckInstruction,
		},
		exampleMessages: {
			value: exampleMessages,
			changeHandler: setExampleMessages,
			checkHandler: handleCheckExampleMessages,
		},
		bio: {
			value: bio,
			changeHandler: setBio,
			checkHandler: handleCheckBio,
		},
	};

	return {
		pronoun,
		setPronoun,
		voice,
		setVoice,
		isPublic,
		setIsPublic: handleChangeIsPublic,
		isAIPopupOpen,
		setIsAIPopupOpen,
		image,
		handleImageChange,
		isAllFilled,
		handleSaveClick,
		isLoading: isLoading || !voices.length,
		isAvatarLoading,
		avatarData,
		errors,
		hasErrors,
		formFields,
		chatPhotosType,
		handleChangeChatPhotosType,
		isCheckLoading,
		voices,
		instruction,
		setInstruction,
		addExampleHook,
		tags,
		handleTagsChange,
		isAutocompleteOpened,
		setIsAutocompleteOpened,
		handleAutocompleteSubmit,
		shouldOpenAvatarSelect,
		handleAutocompleteClose,
	};
};