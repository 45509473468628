import {useState, useContext} from 'react';
import styles from './AutocompleteView.module.css';
import {AutoCompleteBotInfo} from '../../../common-lib/src/api/Api.types';
import {Modal} from '@mui/material';
import {AppContext} from '../../../App';
import {
	CloseModalBtn,
	Spinner,
	InButton,
} from '../../../common-lib/src/components';
import {DialogMobile} from '../CreateBotMobile/TextAreaDialog/TextAreaDialog';
import {AiName} from '../AiAvatar/AIName/AiName';
import {api} from '../../../common-lib/src/api/Api';
import {enqueueSnackbar} from 'notistack';

export type AutocompleteViewProps = {
	onClose: () => void;
	onSubmit: (data: AutoCompleteBotInfo & {avatar: any}) => void;
};

export const AutocompleteView = ({
	onClose,
	onSubmit,
}: AutocompleteViewProps) => {
	const {isMobile} = useContext(AppContext);

	if (isMobile) {
		return (
			<DialogMobile
				title="New bot"
				openned
				onClose={onClose}
				className={styles.dialogMobile}
			>
				<AutocompleteViewInner onSubmit={onSubmit} onClose={onClose} />
			</DialogMobile>
		);
	}

	return (
		<Modal open={true} onClose={onClose}>
			<div className={styles.modal}>
				<div className={styles.modalContainer}>
					<h2 className={styles.title}>New bot</h2>
					<AutocompleteViewInner onSubmit={onSubmit} onClose={onClose} />
				</div>
				<CloseModalBtn onClose={onClose} />
			</div>
		</Modal>
	);
};

export const AutocompleteViewInner = ({onSubmit}: AutocompleteViewProps) => {
	const [description, setDescription] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const [botInfo, avatar] = await Promise.all([
				api.autocompleteBotInfo({
					short_description: description,
				}),
				api.generateAvatarFromText(description, 'realistic'),
			]);
			onSubmit({...botInfo, avatar});
		} catch (error) {
			//@ts-ignore
			enqueueSnackbar(error?.message || 'Something went wrong', {
				variant: 'error',
			});
			onSubmit({
				name: '',
				pronoun: '',
				appearance: '',
				bio: '',
				greeting: '',
				instruction: '',
				description: '',
				message_examples: [],
				avatar: null,
			});
		} finally {
			setLoading(false);
		}
	};
	return (
		<div className={styles.container}>
			<AiName
				onChange={setDescription}
				value={description}
				className={styles.textArea}
				placeholder="Describe your character in a sentence — their personality, background, or purpose"
			/>
			<InButton
				id="continue-button"
				onClick={handleSubmit}
				isFilled
				className={styles.continueButton}
				isDisabled={!description}
			>
				Continue
			</InButton>
			{loading && <Spinner withLayout />}
		</div>
	);
};
