import {useEffect, useState} from 'react';
import styles from './GeneratePhotosSelector.module.css';
import cn from 'classnames';
import arrowDark from './images/arrow-dark.svg';
import checkImg from './images/check.svg';
import {GeneratePhotosDataType} from '../../../api/types';

const generatePhotosData: GeneratePhotosDataType[] = [
	'realistic',
	'anime',
	'disabled',
];

type Props = {
	onSelect: (emotion: GeneratePhotosDataType) => void;
	value: GeneratePhotosDataType | null;
};

export const GeneratePhotosSelector = ({onSelect, value}: Props) => {
	const [opened, setOpened] = useState(false);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!(e.target as HTMLElement)?.closest(`.${styles.container}`)) {
			setOpened(!opened);
			e.stopPropagation();
		}
	};

	const handleClose = (e: MouseEvent) => {
		if (
			!(e.target as HTMLElement)?.closest(`.${styles.selector}`) &&
			!(e.target as HTMLElement)?.classList?.contains(styles.row)
		) {
			setOpened(false);
		}
	};

	useEffect(() => {
		if (opened) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, [opened]);

	const text = value ? `${value.charAt(0).toUpperCase() + value.slice(1)}` : '';

	const handleSelect = (
		e: React.MouseEvent<HTMLDivElement>,
		generatePhotos: GeneratePhotosDataType
	) => {
		e.stopPropagation();
		e.preventDefault();
		onSelect(generatePhotos);
		setOpened(false);
	};

	return (
		<div className={styles.selector} onClick={handleClick}>
			<p className={styles.name}>{text}</p>
			<img
				src={arrowDark}
				alt="arrow"
				className={cn(styles.arrow, opened ? styles.arrowRevert : '')}
			/>
			{opened && (
				<div className={styles.container}>
					{generatePhotosData.map((generatePhotos) => {
						const selected = value === generatePhotos;
						return (
							<div
								className={styles.row}
								onClick={(e) => handleSelect(e, generatePhotos)}
								key={generatePhotos}
							>
								<div
									className={cn(
										styles.rowName,
										selected ? styles.rowNameSelected : ''
									)}
								>
									<p>{`${generatePhotos}`}</p>
								</div>
								{selected && (
									<img
										className={styles.rowSelectedImg}
										src={checkImg}
										alt="check"
									/>
								)}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
