import {useState} from 'react';
import {BotifyTextArea} from '../../BotifyTextArea/BotifyTextArea';
import {FIELD_NAMES, FIELD_PLACEHOLDERS} from '../constants';
import {
	countExampleMessagesArrayPercantage,
	countPercantageByType,
	DialogType,
} from '../utils';
import styles from './EditField.module.css';
import cn from 'classnames';
import arrow from './images/arrow.svg';
import {PercentProgress} from '../SettingsRow';
import {DIALOG_IMAGES} from '../images/dialogImages';
import {ExampleMessage} from '../../CreateBot.types';
import {InButton} from '../../../../common-lib/src/components';
import {ExampleMessageComponent} from '../../ExampleMessages/ExampleMessage/ExampleMessage';

type Props = {
	type: DialogType;
	disabled?: boolean;
	value: string;
	onChange: (value: string) => void;
	onCheck: (value: string) => void;
	hasError: boolean;
	injected?: boolean;
};

export const EditFieldInner = ({
	type,
	disabled,
	value,
	onChange,
	onCheck,
	hasError,
}: Props) => {
	return (
		<div className={styles.block}>
			<div className={styles.blockContent}>
				<BotifyTextArea
					value={value}
					onChange={onChange}
					placeholder={FIELD_PLACEHOLDERS[type]}
					onCheck={onCheck}
					showError={hasError}
					disabled={disabled}
					type={type}
					className={styles.textArea}
				/>
			</div>
		</div>
	);
};

type ContainerProps = {
	children: React.ReactNode;
	type: DialogType;
	value: string | ExampleMessage[];
	injected?: boolean;
};

export const EditField = (props: Props) => {
	return (
		<EditFieldContainer
			type={props.type}
			value={props.value}
			injected={props.injected}
		>
			<EditFieldInner {...props} />
		</EditFieldContainer>
	);
};

const EditFieldContainer = ({
	children,
	type,
	value,
	injected,
}: ContainerProps) => {
	const [isOpen, setIsOpen] = useState(true);
	const percentage =
		type === 'exampleMessages'
			? countExampleMessagesArrayPercantage(value.length)
			: countPercantageByType(type, value.length);

	const image = DIALOG_IMAGES[type];

	return (
		<div className={cn(styles.collapsible, {[styles.injected]: injected})}>
			<div className={styles.collapsibleHeader}>
				{image && !injected && <img src={image} alt={type} />}
				<h3 className={styles.blockTitle}>{FIELD_NAMES[type]}</h3>
				<PercentProgress percentage={percentage} />
				{!injected && (
					<img
						src={arrow}
						alt={isOpen ? 'arrow-up' : 'arrow-down'}
						className={cn(styles.arrow, {
							[styles.arrowOpen]: isOpen,
						})}
						onClick={() => setIsOpen(!isOpen)}
					/>
				)}
			</div>
			{isOpen && children}
		</div>
	);
};

type ArrayFieldProps = {
	disabled?: boolean;
	value: ExampleMessage[];
	onChange: (value: ExampleMessage[]) => void;
	onCheck: (value: ExampleMessage[]) => void;
	hasError: boolean;
	open: () => void;
	loading: boolean;
};

export const ExampleMessagesField = ({
	disabled,
	value,
	onChange,
	onCheck,
	hasError,
	open,
	loading,
}: ArrayFieldProps) => {
	// TODO: add check hasError and onCheck
	return (
		<EditFieldContainer type="exampleMessages" value={value}>
			<ExampleMessagesInner
				value={value}
				onChange={onChange}
				onCheck={onCheck}
				hasError={hasError}
				open={open}
				disabled={disabled}
				loading={loading}
			/>
		</EditFieldContainer>
	);
};

type ExampleMessagesInnerProps = {
	value: ExampleMessage[];
	onChange: (value: ExampleMessage[]) => void;
	onCheck: (value: ExampleMessage[]) => void;
	hasError: boolean;
	open: () => void;
	disabled?: boolean;
	loading: boolean;
};

export const ExampleMessagesInner = ({
	value,
	onChange,
	onCheck,
	hasError,
	open,
	disabled,
	loading,
}: ExampleMessagesInnerProps) => {
	const handleDelete = (index: number) => {
		onChange(value.filter((_, i) => i !== index));
	};

	return (
		<div className={styles.block}>
			<div className={styles.blockContent}>
				{value.map((item, index) => (
					<ExampleMessageComponent
						key={item.text + index}
						text={item.text}
						onDelete={() => handleDelete(index)}
					/>
				))}
			</div>
			{hasError && (
				<p className={styles.error}>
					Inappropriated content. Please, change text
				</p>
			)}
			<InButton
				onClick={open}
				id="add-example-button"
				className={styles.addExampleButton}
				isDisabled={disabled}
				loading={loading}
			>
				{loading ? <>Loading</> : <>Add example</>}
			</InButton>
		</div>
	);
};
