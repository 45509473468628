import {DialogMobile} from '../TextAreaDialog/TextAreaDialog';
import {TextArea} from '../../../../common-lib/src/components/TextArea/TextArea';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import styles from './PublicProfileDialog.module.css';
import {CategoryData} from '../../../../api/types';
import {FIELD_PLACEHOLDERS} from '../constants';
import {countPercantageByType} from '../utils';
import {Spinner} from '../../../../common-lib/src/components';

type Props = {
	openned: boolean;
	onClose: () => void;
	bio: string;
	onBioChange: (value: string) => void;
	tags: CategoryData[];
	onTagsChange: (tags: CategoryData[]) => void;
	onOpenTagsDialog: () => void;
	onCheck: (v: string) => Promise<boolean | undefined>;
	hasError: boolean;
	disabled: boolean;
	loading: boolean;
};

export const PublicProfileDialog = ({
	openned,
	onClose,
	bio,
	onBioChange,
	tags,
	onOpenTagsDialog,
	onCheck,
	hasError,
	disabled,
	loading,
}: Props) => {
	const percentage = countPercantageByType('bio', bio.length);

	const handleClose = async () => {
		const isValid = await onCheck(bio);
		if (isValid) {
			onClose();
		}
	};

	return (
		<DialogMobile
			openned={openned}
			onClose={handleClose}
			title="Public Profile"
			percentage={percentage}
		>
			<div className={styles.content}>
				<TextArea
					value={bio}
					onChange={onBioChange}
					placeholder={FIELD_PLACEHOLDERS.bio}
					className={styles.bio}
					showError={hasError}
					disabled={disabled}
				/>
				{loading && <Spinner withLayout />}
				<TagsSection tags={tags} onOpenTagsDialog={onOpenTagsDialog} />
			</div>
			<InButton
				className={styles.saveButton}
				onClick={handleClose}
				id="save-public-profile-button"
				isFilled
			>
				Done
			</InButton>
		</DialogMobile>
	);
};

export const TagsSection = ({
	tags,
	onOpenTagsDialog,
}: {
	tags: CategoryData[];
	onOpenTagsDialog: () => void;
}) => {
	return (
		<div className={styles.tagsSection}>
			<InButton
				className={styles.tagsButton}
				onClick={onOpenTagsDialog}
				id="edit-tags-button"
			>
				{tags.length > 0 ? 'Edit Tags' : 'Add Tags'}
			</InButton>
			{tags.length > 0 &&
				tags.map((tag) => (
					<div key={tag.id} className={styles.tag}>
						{tag.attributes.name}
					</div>
				))}
		</div>
	);
};
