import {useContext, useEffect, useState} from 'react';
import {MainContainer} from '../../Main/MainContainer';
import styles from './EditProfilePage.module.css';
import {AppContext} from '../../../App';
import {EditProfileHeader} from '../ProfileHeader/EditProfileHeader';
import {AvatarSelector} from '../../CreateBot/AvatarSelect/AvatarSelector';
import {InButton, Spinner} from '../../../common-lib/src/components';
import {usersApi} from '../../../api/usersApi/usersApi';
import {UserPronoun} from '../../../api/usersApi/usersApi.types';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {AiAvatar} from '../../CreateBot/AiAvatar/AiAvatar';
import {AiAvatarMobile} from '../../CreateBot/AiAvatar/mobile/AiAvatarMobile';
import {EditTextField} from '../../CreateBot/EditTextField';

type Form = {
	username: string;
	description: string;
};

export const EditProfilePage = () => {
	const {user, handleUpdateUser, isMobile, isLoadingUser} =
		useContext(AppContext);
	const navigate = useNavigate();
	const {enqueueSnackbar} = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [form, setForm] = useState<Form>({
		username: user?.username || '',
		description: user?.description || '',
	});

	const [isAIPopupOpen, setIsAIPopupOpen] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [file, setFile] = useState<File | null>(null);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		setDisabled(
			form.username.length < 3 ||
				(form.description.length < 3 && form.description.length > 0)
		);
	}, [form]);

	useEffect(() => {
		setForm({
			...form,
			username: user?.username || '',
			description: user?.description || '',
		});
		setImage(user?.avatar || null);
		setFile(null);
	}, [user]);

	const handleChangeField = (
		field: keyof Form,
		value: string | UserPronoun
	) => {
		setForm((prev) => ({
			...prev,
			[field]:
				field === 'username' ? value?.replace(/[^a-zA-Z0-9_-]/g, '') : value,
		}));
	};

	const createHandleChangeField = (field: keyof Form) => {
		return (value: string) => {
			handleChangeField(field, value);
		};
	};

	const handleSave = async () => {
		setIsLoading(true);
		const data = {...form};
		Object.keys(data).forEach((key) => {
			if (!data[key as keyof Form]) {
				delete data[key as keyof Form];
			}
		});
		await Promise.all([handleUpdateProfile(data), handleUploadAvatar()])
			.then(() => {
				enqueueSnackbar('Profile updated', {variant: 'success'});
				navigate('/profile');
			})
			.catch((e) => {
				console.error(e);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleUpdateProfile = async (data: Form) => {
		try {
			await usersApi.updateMe(data);
			handleUpdateUser(data);
		} catch (e) {
			console.error(e);
			enqueueSnackbar(e?.toString(), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			throw e;
		}
	};

	const handleUploadAvatar = async () => {
		if (!file) {
			return;
		}
		try {
			const res = await usersApi.uploadAvatar(file);
			handleUpdateUser({avatar: res.avatar});
			enqueueSnackbar('Avatar uploaded', {variant: 'success'});
		} catch (e) {
			console.error(e);
			enqueueSnackbar(e?.toString(), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			throw e;
		}
	};

	const handleImageChange = (file: File | null, value: string | null) => {
		setImage(value);
		setFile(file);
	};

	return (
		<MainContainer header={<EditProfileHeader />}>
			<div className={styles.content}>
				{(isLoading || isLoadingUser) && <Spinner withLayout />}
				<AvatarSelector
					showError={false}
					onGenerateClick={() => setIsAIPopupOpen(true)}
					value={image}
					onChange={handleImageChange}
					loading={false}
					avatarData={null}
				/>
				<EditTextField
					oneLine
					value={form.username}
					onChange={createHandleChangeField('username')}
					placeholder="Username"
				/>
				<EditTextField
					value={form.description}
					onChange={createHandleChangeField('description')}
					placeholder="Tell us a little about yourself in free form for other users."
					title="Bio"
				/>
				<InButton
					id="save-user-button"
					isFilled
					className={styles.createButton}
					onClick={handleSave}
					isDisabled={disabled}
				>
					Save
				</InButton>
			</div>
			{isMobile ? (
				<AiAvatarMobile
					openned={isAIPopupOpen}
					onClose={() => setIsAIPopupOpen(false)}
					onChange={handleImageChange}
				/>
			) : (
				<AiAvatar
					openned={isAIPopupOpen}
					onClose={() => setIsAIPopupOpen(false)}
					onChange={handleImageChange}
				/>
			)}
		</MainContainer>
	);
};
