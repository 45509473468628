import {DialogType} from '../utils';
import appearance from './appearance.svg';
import description from './description.svg';
import greeting from './firstMessage.svg';
import instruction from './instruction.svg';
import exampleMessages from './exampleMessages.svg';
import publicProfile from './publicProfile.svg';

export const DIALOG_IMAGES: Record<DialogType, string> = {
	appearance,
	description,
	greeting,
	instruction,
	exampleMessages,
	bio: publicProfile,
	name: '',
};
