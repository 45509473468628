export const TARGET_LENGTH: Record<DialogType, number> = {
	description: 500,
	appearance: 350,
	greeting: 200,
	exampleMessages: 5,
	instruction: 400,
	bio: 100,
	name: 50,
};

export const BOT_MAX_LENGTH: Record<DialogType, number> = {
	appearance: 2000,
	description: 8000,
	name: 50,
	greeting: 600,
	exampleMessages: 10,
	instruction: 500,
	bio: 140,
};

export const EXAMPLE_MESSAGE_MAX_LENGTH = 250;

export type DialogType =
	| 'description'
	| 'appearance'
	| 'exampleMessages'
	| 'instruction'
	| 'bio'
	| 'name'
	| 'greeting';

export const countPercantage = (target: number, value: number) => {
	return Math.min((value / target) * 100, 100);
};

export const countDescriptionPercantage = (value: number) => {
	return countPercantage(TARGET_LENGTH.description, value);
};
export const countAppearancePercantage = (value: number) => {
	return countPercantage(TARGET_LENGTH.appearance, value);
};
export const countFirstMessagePercantage = (value: number) => {
	return countPercantage(TARGET_LENGTH.greeting, value);
};

export const countExampleMessagesArrayPercantage = (value: number) => {
	return countPercantage(TARGET_LENGTH.exampleMessages, value);
};

export const countExampleMessageTextPercantage = (value: number) => {
	return countPercantage(EXAMPLE_MESSAGE_MAX_LENGTH, value);
};

export const countPercantageByType = (type: DialogType, value: number) => {
	switch (type) {
		case 'description':
			return countDescriptionPercantage(value);
		case 'appearance':
			return countAppearancePercantage(value);
		case 'greeting':
			return countFirstMessagePercantage(value);
		case 'instruction':
			return countPercantage(TARGET_LENGTH.instruction, value);
		case 'exampleMessages':
			return countExampleMessageTextPercantage(value);
		case 'bio':
			return countPercantage(TARGET_LENGTH.bio, value);
		default:
			throw new Error('Unknown type');
	}
};
