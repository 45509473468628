import styles from './ExampleMessage.module.css';
import cross from './images/cross.svg';

type Props = {
	text: string;
	onDelete: () => void;
};

export const ExampleMessageComponent = ({text, onDelete}: Props) => {
	return (
		<div className={styles.exampleMessage}>
			<p className={styles.text}>{text}</p>
			<div className={styles.delete} onClick={onDelete}>
				<img src={cross} alt="delete" />
			</div>
		</div>
	);
};
