import {useState, useEffect} from 'react';
import {CategoryAndBots} from '../../api/apiHelper';
import {BotData} from '../../api/types';
import {CreateCharacterBtnSquare} from '../CreateCharacterBtn/CreateCharacterBtn';
import {useTilesRow} from '../TilesRow/TilesRow';
import {RandomTile} from './RandomTile/RandomTile';
import styles from './TilesPlateV2.module.css';
import {TileV2} from './TileV2/TileV2';

type Props = {
	bots: BotData[];
	categoryData?: CategoryAndBots;
	showRandomTile?: boolean;
};

export const TilesPlateV2 = ({
	bots,
	categoryData,
	showRandomTile = false,
}: Props) => {
	const data = categoryData?.data;
	const {handleBotClick, isMobile} = useTilesRow({
		categoryData,
		bots,
		isPreview: false,
		skipEvents: false,
		index: data?.id ? Number(data.id) : 0,
	});

	const isMasonry = showRandomTile && bots.length > 0 && isMobile;

	if (isMasonry) {
		return <TilesPlateV2Masonry bots={bots} handleBotClick={handleBotClick} />;
	}

	return (
		<div className={styles.container}>
			{showRandomTile && bots.length > 0 && !isMasonry && <RandomTile />}
			{bots.map((bot) => (
				<TileV2 bot={bot} key={bot.id} onClick={() => handleBotClick(bot)} />
			))}
		</div>
	);
};

const TilesPlateV2Masonry = ({
	bots,
	handleBotClick,
}: Props & {handleBotClick: (bot: BotData) => void}) => {
	const [leftColumn, setLeftColumn] = useState<BotData[]>([]);
	const [rightColumn, setRightColumn] = useState<BotData[]>([]);

	useEffect(() => {
		const left: BotData[] = [];
		const right: BotData[] = [];

		bots.forEach((bot, index) => {
			if (index % 2 === 0) {
				right.push(bot);
			} else {
				left.push(bot);
			}
		});

		setLeftColumn(left);
		setRightColumn(right);
	}, [bots]);

	return (
		<div className={styles.masonry}>
			<div className={styles.masonryColumn} key="left">
				<CreateCharacterBtnSquare />
				{leftColumn.map((bot) => (
					<TileV2 bot={bot} key={bot.id} onClick={() => handleBotClick(bot)} />
				))}
			</div>
			<div className={styles.masonryColumn} key="right">
				{rightColumn.map((bot) => (
					<TileV2 bot={bot} key={bot.id} onClick={() => handleBotClick(bot)} />
				))}
			</div>
		</div>
	);
};
